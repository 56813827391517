<template>
  <Shape>
    <bb-loader v-if="loading" />
    <h4 class="text-2xl">Handling card</h4>
    <base-alert
      type="error"
      v-if="confirmError"
    >
      <template #title> Error </template>
      <template #content>
        <div>Something went wrong when confirming your card</div>
      </template>
      <template #action>
        <a
          v-if="!!manager"
          :href="mailTo"
          target="_blank"
        >
          <the-button error> Contact CSM </the-button>
        </a>
      </template>
    </base-alert>
  </Shape>
</template>

<script>
import BbLoader from '@/components/loader/BBLoader'
import Shape from '@/components/base/Shape.vue'
import BaseAlert from '@/components/alert/BaseAlert'
import Toast from '@/components/shared/Toast'
import { mapGetters } from 'vuex'

export default {
  name: 'completed',
  components: { Shape, BbLoader, BaseAlert, Toast },
  data() {
    return {
      loading: false,
      status: null,
      confirmError: true,
    }
  },
  computed: {
    ...mapGetters({
      manager: 'manager/getAccountManager',
      account: 'account/getAccount',
    }),
    mailTo() {
      return (
        `mailto:${this.manager.email}?subject=Problem when adding payment method!&body=Hello! I'm getting an ` +
        `error when I'm trying to connect a credit card! Please help! My account is ${this.account.name}`
      )
    },
  },

  methods: {
    async confirmCard() {
      this.confirmError = null
      this.loading = true

      try {
        const res = await this.$http.get(
          `card/confirmation/${this.$route.query.setup_intent}/${this.$route.query.card_id}`,
        )
        this.status = res.data.data.status

        if (this.status === 'succeeded') {
          this.$toast.success({
            component: Toast,
            props: {
              title: 'Success',
              message: 'Card saved successfully',
              type: 'success',
            },
          })
          this.$router.push({ path: '/console/account/billing/payment-methods' })
        }
        if (this.status === 'canceled') {
          this.$toast.error({
            component: Toast,
            props: {
              title: 'Error',
              message: 'Card could not be confirmed',
              type: 'error',
            },
          })
          this.$router.push({ path: '/console/account/billing/payment-methods' })
        }
      } catch (e) {
        this.confirmError = e
      } finally {
        this.loading = false
      }
    },
  },
  async beforeMount() {
    this.confirmCard()
  },
}
</script>
